import styled from 'styled-components'
import { Link } from 'react-router-dom'; 

export const Button = styled(Link)`
    background: ${({ primary }) => (primary ? 'rgba(900, 120 , 29, 0.897)' : '#CD853F')}; 
    color: ${({ primary }) => (primary ? 'rgba(900, 100 , 29, 0.897)' : '#CD853F')}; 
    white-space: nowrap;
    outline: none;
    border: none;
    width: 80%;
    max-width: 300px;
    height: 80%;
    cursor: pointer;
    text-decoration: none; 
    transition: 0.3s;
    display: inline-flex; 
    align-items: center;
    padding: ${({big}) => (big ? '16px 40px' : '14px 24px')};
    font-size: ${({big}) => (big ? '20px' : '14px')}; 
    border-radius: ${({round}) => (round ? '50px' : '0px')};

    &:hover {
        transform: translateY(-2px);
    }

    @media (max-width: 650px) {
    text-align: center;
    margin-right: 60%;
    }
`