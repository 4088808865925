import Imageone from "../images/roomshero.jpg";
import Imagetwo from "../images/Imagetwo.jpg";
import Imagethree from "../images/Imagethree.jpg";
import Imagefour from "../images/Imagefour.jpg";
import Imagefive from "../images/Imagefive.jpg";
import Imagesix from "../images/Imagesix.jpg";
import Imageeleven from "../images/Imageeleven.jpg";
import Imagetwelve from "../images/Imagetwelve.jpg";
import Imagefifteen from "../images/littleheavenb&b.jpg";
import Imagesixteen from "../images/thelodgeinside.jpg";
import Imageseventeen from "../images/littleheavenbedandbreakfast.jpg";
import Imageeighteen from "../images/littleheavenbb.jpg";
import Imagenineteen from "../images/comfort.jpg";
import Imagetwenty from "../images/aromas.jpg";
import Imagetwentyone from "../images/Imagetwentyone.jpg";
import Imagetwentytwo from "../images/Imagetwentytwo.jpg";
import Imagetwentythree from "../images/Imagetwentythree.jpg";
import yadkinvalley from "../images/yadkinvalley.png";
import pilotmountain from "../images/pilotmountain.jpg";
import lodgeseven from "../images/paradise.jpg";
import lodgeeight from "../images/breakfast.jpg";
import Tree from "../images/tree.jpg";
import Thelodge from "../images/thelodgefront.jpg";
import Grapes from "../images/visit-yadkin-hero.jpg";
import diningpic from "../images/diningpic.jpg";
import artscouncil from "../images/artscouncil.jpg";
import wineries from "../images/wineries.png";
import entrance from "../images/inside.jpg";
import entrancetwo from "../images/insidelodge.jpg";
import entrancethree from "../images/entrancethree.jpg";
import outside from "../images/outsidetwo.jpg";
import inside from "../images/outsidethree.jpg";
import bathroom from "../images/lodgehero.jpg";

export const SliderData = [
  {
    title: (
      <h3 style={{ textDecoration: "italisize", marginRight: "0" }}>
        Join us at Little Heaven Bed & Breakfastn
      </h3>
    ),
    path: "/home",
    label: "View Room",
    image: Imageone,
    alt: "Room",
  },
  {
    title: <h3>Faith Guest Room</h3>,
    titletwo: <h2>Explore our beautiful rooms!</h2>,
    path: "/home",
    label: "View Room",
    image: Imagetwo,
    alt: "Room",
  },
  {
    title: <h3>Love Guest Room</h3>,
    titletwo: <h2>Get in touch with nature!</h2>,
    path: "/home",
    label: "View Room",
    image: Imagethree,
    alt: "Room",
  },
  {
    title: <h3>Hope Guest Room</h3>,
    titletwo: <h2>Explore local wineries and mountains!</h2>,
    path: "/home",
    label: "View Room",
    image: Imagefour,
    alt: "Room",
  },
  {
    title: <h3>Grace Guest Room</h3>,
    titletwo: <h2>Taste the food from the kitchen!</h2>,
    path: "/home",
    label: "View Room",
    image: Imagefive,
    alt: "Room",
  },
  {
    title: <h3>Peace Guest Room</h3>,
    titletwo: <h2>Enjoy our softest sheets, towels, and pillows!</h2>,
    path: "/home",
    label: "View Room",
    image: Imagesix,
    alt: "Room",
  },
];

export const SliderDataTwo = [
  {
    title: "Love Roses, Chocolate Covered Strawberries or Wine?",
    path: "/lodging",
    image: "https://i.postimg.cc/fLmvx8t4/Capture.png",
    alt: "Lodge",
  },
  {
    title: "Packages for the Perfect Getaway!",
    path: "/lodging",
    image:
      "https://i.postimg.cc/3xLyc25k/yadkinvalleylittleheavenbedandbreakfast.jpg",
    alt: "Lodge",
  },
  {
    title: "Make your stay even more enjoyable!",
    path: "/lodging",
    image: "https://i.postimg.cc/sXhw26fG/littleheavenbedandbreakfast.jpg",
    alt: "Lodge",
  },
];

export const SliderDataThree = [
  {
    title: "Welcome to Little Heaven Bed & Breakfast",
    titletwo: "a true experience of Peace, Joy, Love, Hope, and Faith",
    image: Imageseventeen,
    alt: "Lodge",
  },
  {
    title: "From Modern Decor to Luxurious Comfort",
    titletwo: "You will forget where you are",
    image: bathroom,
    alt: "Lodge",
  },
  {
    title: "Six Unique and Elegant Rooms",
    titletwo: "for the perfect getaway",
    image: Imagefifteen,
    alt: "Lodge",
  },
  {
    title: "Mountain Views Surround You",
    titletwo: "relax and let them take your worries away",
    image: outside,
    alt: "Lodge",
  },
  {
    title: "An Exquisite, Rustic Experience",
    titletwo: "The openness carries itself inside",
    image: inside,
    alt: "Lodge",
  },
  {
    title: "We Thank you",
    titletwo: "for choosing Little Heaven Bed & Breakfast",
    image: Imageeighteen,
    alt: "Lodge",
  },
];

export const SliderDataFour = [
  {
    title: "You will fall in love with ALL six rooms!",
    titletwo: "each one with new amenities and fully renovated",

    alt: "Lodge",
  },
  {
    title: "Your comfort is our TOP priority!",
    titletwo: "We invite you to experience luxury like never before!",
    image: Imagenineteen,
    alt: "Lodge",
  },
  {
    title: "Fresh coffee aromas travel through the mountian air",
    titletwo: "every morning at Little Heaven",
    image: Imagetwenty,
    alt: "Lodge",
  },
];

export const SliderDataFive = [
  {
    title: "The perfect country setting for Weddings & Celebrations",
    image: Imagetwentyone,
    alt: "Lodge",
  },
  {
    title: "Plenty of beautiful indoor and outdoor space for your special day!",
    image: Imagetwentytwo,
    alt: "Lodge",
  },
  {
    title: "Thank you for allowing us to host your dream wedding!",
    image: Imagetwentythree,
    alt: "Lodge",
  },
];

export const SliderDataSix = [
  {
    title: "Explore the beautiful Yadkin Valley",
    image: yadkinvalley,
    alt: "Lodge",
    titletwo: "View from the Blue Ridge Parkway",
  },
  {
    title: "Hiking, Fishing, or Wine Tasting",
    image: pilotmountain,
    alt: "Lodge",
    titletwo: "View of Pilot Mountain",
  },
];

export const SliderDataSeven = [
  {
    title: "Welcome to our Policies",
    image: "https://i.postimg.cc/sXhw26fG/littleheavenbedandbreakfast.jpg",
    alt: "Lodge",
    titletwo: "",
  },
];

export const SliderDataEight = [
  {
    title: "Breakfast & Afternoon Treats",
    image: lodgeeight,
    alt: "Lodge",
    titletwo: "",
  },
];

export const SliderDataNine = [
  {
    title: "Directions",
    image: "https://i.postimg.cc/sgfQd3Rd/IMG-0120.jpg",
    alt: "Lodge",
    titletwo: "",
  },
];

export const SliderDataTen = [
  {
    title: "Policies",
    image: "https://i.postimg.cc/HLg8jTQL/IMG-7116.jpg",
    alt: "Lodge",
    titletwo: "",
  },
];

export const SliderDataEleven = [
  {
    title: "Things to Do",
    image: Grapes,
    alt: "Lodge",
    titletwo: "",
  },
];

export const SliderDataTwelve = [
  {
    title: "Yadkin Valley Dining",
    image: diningpic,
    alt: "Lodge",
    titletwo: "",
  },
];

export const SliderDataThirteen = [
  {
    title: "Yadkin Valley Arts & Music",
    image: artscouncil,
    alt: "Lodge",
    titletwo: "",
  },
];

export const SliderDataFourteen = [
  {
    title: "Yadkin Valley Wineries",
    image: wineries,
    alt: "Lodge",
    titletwo: "",
  },
];
